"use client";
import classNames from "classnames";
import { type FC } from "react";

type Props = {
  className?: string
}

export const SubHeader: FC<Props> = ({ className }) =>
  <section className={classNames(`mt-14 mb-8`, [className])}>
    <h1 className="text-center w-full text-xl font-semibold md:text-3xl xs:text-2xl mt-b xs:mb-3">
      Dashboard
    </h1>
  </section>
