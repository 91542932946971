"use client";
import { type FC } from "react";
import Link from "next/link";
import classNames from "classnames";
import { CtaSecondary } from "..";
import { generalLiterals } from "../../literals";
import { BasicProps } from "../types/BasicProps";
import { displayDollarValue } from "utils";
import track from "logic/track";

type Props = BasicProps & {
  totalDebtOfUser?: bigint;
  nftsDepositedByUser?: number;
  borrowApy?: number;
};

const {
  amountBorrowedLabel,
  borrowLinkLabel,
  interestRateLabel,
  nftsCollateralizedLabel,
  title,
} = generalLiterals.dashboard.loansBox;

export const LoansBox: FC<Props> = (props) => {
  const { className, totalDebtOfUser, nftsDepositedByUser, borrowApy } = props;

  return (
    <section
      className={classNames("h-auto rounded-xl p-0.5 border-unlockd", [
        [className],
      ])}
    >
      <div className="h-full flex flex-col justify-between gap-9 pt-[36px] pr-[31px] pl-[34px] pb-[46px] bg-secondary rounded-xl">
        <div className="flex flex-col gap-7">
          <h3 className="font-bold text-2xl text-uyellow">{title}</h3>
          <div className="h-full flex flex-col gap-5">
            <div className="flex justify-between w-full">
              <span className="font-semibold">{nftsCollateralizedLabel}</span>
              <p>{nftsDepositedByUser}</p>
            </div>
            <div className="flex justify-between w-full">
              <span className="font-semibold">{amountBorrowedLabel}</span>
              <div className="flex items-center gap-1">
                <p>
                  {totalDebtOfUser !== undefined &&
                    (totalDebtOfUser === BigInt(0)
                      ? "$0"
                      : displayDollarValue(totalDebtOfUser))}
                </p>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <span className="font-semibold">{interestRateLabel}</span>
              <p>{borrowApy && (borrowApy * 100).toFixed(2)} %</p>
            </div>
          </div>
        </div>
        <Link legacyBehavior href="/borrow">
          <a onClick={() => track.click('dashboard_click_borrow')}>
            <CtaSecondary className="mx-auto w-40">
              {borrowLinkLabel}
            </CtaSecondary>
          </a>
        </Link>
      </div>
    </section>
  );
};
