import classNames from "classnames";
import Link from "next/link";
import { CtaSecondary } from "..";
import { BasicProps } from "../types/BasicProps";
import { generalLiterals } from "../../literals";
import { FC } from "react";
import { displayDollarValue } from "utils";
import track from "logic/track";

type Props = BasicProps & {
  supply?: bigint;
  interestEarned?: bigint;
  earnApy?: number;
};

const {
  title,
  apyLabel,
  earnLinkLabel,
  fundsSuppliedLabel,
  interestEarnedLabel,
} = generalLiterals.dashboard.earnBox;

export const EarnBox: FC<Props> = (props) => {
  const { className, supply, interestEarned, earnApy: apy } = props;

  return (
    <section
      className={classNames("h-auto rounded-xl p-0.5 border-unlockd", [
        className,
      ])}
    >
      <div className="h-full flex flex-col justify-between gap-9 pt-[36px] pr-[31px] pl-[34px] pb-[46px] bg-secondary rounded-xl">
        <div className="flex flex-col gap-7">
          <h3 className="font-bold text-2xl text-uyellow">{title}</h3>
          <div className="h-full flex flex-col gap-5">
            <div className="flex justify-between w-full">
              <span className="font-semibold">{fundsSuppliedLabel}</span>
              <div className="flex items-center gap-1">
                <p>
                  {supply !== undefined &&
                    (supply === BigInt(0) ? "$0" : displayDollarValue(supply))}
                </p>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <span className="font-semibold">{interestEarnedLabel}</span>
              <div className="flex items-center gap-1">
                <p>
                  {interestEarned !== undefined &&
                    (interestEarned === BigInt(0)
                      ? "$0"
                      : displayDollarValue(interestEarned))}
                </p>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <span className="font-semibold">{apyLabel}</span>
              <p>{apy && (apy * 100).toFixed(2)} % </p>
            </div>
          </div>
        </div>

        <Link legacyBehavior href="/earn">
          <a onClick={() => track.click('dashboard_click_earn')}>
            <CtaSecondary className="mx-auto w-40">
              {earnLinkLabel}
            </CtaSecondary>
          </a>
        </Link>
      </div>
    </section>
  );
};
