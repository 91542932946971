"use client";
import { SubHeader } from "../../components/dashboard";
import { useEffect, useState } from "react";
import { useCustomWallet } from "../../components/providers";
import dashboardModule, {
  DashboardGeneralData,
  DashboardUserData,
} from "../../logic/DashboardModule";
import useAlertNotification from "../../components/hooks/useAlertNotification";
import { handleErrors } from "../../errors";
import { CollectionsPanel } from "../../components/dashboard/CollectionsPanel";
import { AuctionsBox } from "../../components/dashboard/AuctionsBox";
import { EarnBox } from "../../components/dashboard/EarnBox";
import { LoansBox } from "../../components/dashboard/LoansBox";
import { ProtocolMetrics } from "../../components/dashboard/ProtocolMetrics";
import { useChain } from "components/providers/ChainProvider";

export default function Dashboard(): JSX.Element {
  const { isConnected, address } = useCustomWallet();
  const { chainId } = useChain();
  const [dashboardGeneralData, setDashboardGeneralData] =
    useState<DashboardGeneralData>();
  const [dashboardUserData, setDashboardUserData] =
    useState<DashboardUserData>();
  const [error, setError] = useState<Error | null>(null);
  const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();

  useEffect(() => {
    if (error && !isAlertNotificationOpen) {
      openAlertNotification("error", error.message, 5000);
    }
  }, [error]);

  useEffect(() => {
    handleGetDashboardGeneralData();
  }, [chainId]);

  useEffect(() => {
    address && handleGetDashboardUserData();
  }, [address, chainId]);

  const handleGetDashboardUserData = async () => {
    try {
      setDashboardUserData(await dashboardModule.getDashboardUserData(chainId));
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  const handleGetDashboardGeneralData = async () => {
    try {
      setDashboardGeneralData(
        await dashboardModule.getDashboardGeneralData(chainId)
      );
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  return (
    <>
      <main className="relative overflow-hidden px-4 pb-20">
        <SubHeader />
        <div className="mt-[74px] max-w-[1220px] mx-auto flex flex-col gap-10">
          <ProtocolMetrics
            className="w-full"
            valueLocked={dashboardGeneralData?.totalValueLocked}
            nftsDeposited={dashboardGeneralData?.nftsDeposited}
            utilizationRate={dashboardGeneralData?.utilizationRate}
            totalNftsValue={dashboardGeneralData?.totalNftsValue}
          />
          {isConnected && (
            <div className="flex w-full max-h-max justify-center gap-10">
              <LoansBox
                className="w-1/3"
                nftsDepositedByUser={dashboardUserData?.nftsDeposited}
                totalDebtOfUser={dashboardUserData?.totalDebt}
                borrowApy={dashboardUserData?.borrowApy}
              />
              <EarnBox
                className="w-1/3"
                supply={dashboardUserData?.totalSupplied}
                interestEarned={dashboardUserData?.interestEarned}
                earnApy={dashboardUserData?.earnApy}
              />
              <AuctionsBox
                className="w-1/3"
                claimedAuctions={dashboardUserData?.claimedAuctions}
                onGoingAuctions={dashboardUserData?.onGoingAuctions}
              />
            </div>
          )}
          <CollectionsPanel className="w-full" />
        </div>
      </main>
    </>
  );
}
