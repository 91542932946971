"use client";
import { type FC } from "react";
import classNames from "classnames";
import { CtaSecondary } from "..";
import Link from "next/link";
import { BasicProps } from "../types/BasicProps";
import { generalLiterals } from "../../literals";
import track from "logic/track";

type Props = BasicProps & {
  claimedAuctions?: number;
  onGoingAuctions?: number;
};

const { claimedLabel, myActivityLinkLabel, ongoingLabel, title } =
  generalLiterals.dashboard.auctionsBox;

export const AuctionsBox: FC<Props> = (props) => {
  const { className, onGoingAuctions, claimedAuctions } = props;

  return (
    <section
      className={classNames("h-auto rounded-xl p-0.5 border-unlockd", [
        className,
      ])}
    >
      <div className="h-full flex flex-col justify-between gap-9 pt-[36px] pr-[31px] pl-[34px] pb-[46px] bg-secondary rounded-xl">
        <div className="flex flex-col gap-7">
          <h3 className="font-bold text-2xl text-uyellow">{title}</h3>
          <div className="h-full flex flex-col gap-5">
            <div className="flex justify-between w-full">
              <span className="font-semibold">{ongoingLabel}</span>
              <p>{onGoingAuctions}</p>
            </div>
            <div className="flex justify-between w-full">
              <span className="font-semibold">{claimedLabel}</span>
              <p>{claimedAuctions}</p>
            </div>
          </div>
        </div>

        <Link legacyBehavior href="/marketplace/my-activity">
          <a onClick={() => track.click('dashboard_click_activity')}>
            <CtaSecondary className="mx-auto w-40">
              {myActivityLinkLabel}
            </CtaSecondary>
          </a>
        </Link>
      </div>
    </section>
  );
};
