"use client";
import { type FC } from "react";
import classNames from "classnames";
import { BasicProps } from "../types/BasicProps";
import { generalLiterals } from "../../literals";
import { displayDollarValue } from "utils";

type Props = BasicProps & {
  valueLocked?: bigint;
  utilizationRate?: string;
  nftsDeposited?: number;
  totalNftsValue?: bigint;
};

const {
  nftsDepositedLabel,
  tvlLabel,
  title,
  totalNftsValueLabel,
  utilizationRateLabel,
} = generalLiterals.dashboard.protocolMetrics;

export const ProtocolMetrics: FC<Props> = (props) => {
  const {
    className,
    valueLocked,
    nftsDeposited,
    utilizationRate,
    totalNftsValue,
  } = props;

  return (
    <section
      className={classNames("mx-auto rounded-xl p-[1px] border-unlockd", [
        className,
      ])}
    >
      <div className="pt-[22px] pb-[38px] px-7 bg-secondary rounded-xl">
        <h3 className="mb-6 font-bold text-2xl">{title}</h3>
        <div className="px-5 flex justify-between items-center">
          <div className="flex max-w-max">
            <div className="flex flex-col items-center gap-1">
              <span className="font-semibold text-uyellow">{tvlLabel}</span>
              <div className="flex items-center gap-x-1">
                <p className="font-bold text-4xl">
                  {valueLocked !== undefined
                    ? displayDollarValue(valueLocked)
                    : "$-"}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-gradient-to-t from-transparent via-hovercolor to-transparent w-[1px] h-[68px]"></div>

          <div className="flex flex-col items-center justify-center">
            <span className="font-semibold text-uyellow">
              {utilizationRateLabel}
            </span>
            <p className="font-bold text-4xl">
              {utilizationRate !== undefined ? utilizationRate.toString() : "-"}{" "}
              %
            </p>
          </div>

          <div className="bg-gradient-to-t from-transparent via-hovercolor to-transparent w-[1px] h-[68px]"></div>

          <div className="flex">
            <div className="flex flex-col items-center justify-center">
              <span className="font-semibold text-uyellow">
                {nftsDepositedLabel}
              </span>
              <p className="font-bold text-4xl">
                {nftsDeposited !== undefined ? nftsDeposited : "-"}
              </p>
            </div>
          </div>

          <div className="bg-gradient-to-t from-transparent via-hovercolor to-transparent w-[1px] h-[68px]"></div>

          <div className="flex">
            <div className="flex flex-col items-center justify-center">
              <span className="font-semibold text-uyellow">
                {totalNftsValueLabel}
              </span>
              <div className="flex items-center gap-x-1">
                <p className="font-bold text-4xl">
                  {totalNftsValue !== undefined
                    ? displayDollarValue(totalNftsValue)
                    : "$-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
